import CountDown from '../components/countdown';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export default {
  init() {
    // JavaScript to be fired on all pages
    const countdown = document.querySelector('.m-countdown__component');
    if(countdown) {
      const date = countdown.getAttribute('data-date');

      const format = (t) => {
        return t < 10 ? '0' + t : t;
      };

      const getDate = (date) => {
        return new Date(date);
      }

      const complete = () => {

      };

      const render = (time) => {
        const months = document.querySelector('.m-countdown__item--months');
        months.innerHTML = format(time.months);
        const days = document.querySelector('.m-countdown__item--days');
        days.innerHTML = format(time.days);
        const hours = document.querySelector('.m-countdown__item--hours');
        hours.innerHTML = format(time.hours);
        const minutes = document.querySelector('.m-countdown__item--minutes');
        minutes.innerHTML = format(time.minutes);
        const seconds = document.querySelector('.m-countdown__item--seconds');
        seconds.innerHTML = format(time.seconds);
      }

      // eslint-disable-next-line
      const countdownTimer = new CountDown(
        getDate(date),
        render,
        complete
      );
    }

    const slider = new Swiper('.o-slider', {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 5000,
      },
    });
    console.log(slider);

    const gallery = new Swiper('.o-gallery-slider', {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      roundLengths: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
          spaceBetween: 16,
          centeredSlides: true,
          roundLengths: true,
        },
      },
      autoplay: {
        delay: 5000,
      },
    });
    console.log(gallery);

    const back = $('.back-to-top');
    back.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop: 0}, 800);
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    const google = window.google;

    function initMap( $el ) {

      // Find marker elements within map.
      var $markers = $el.find('.marker');
  
      // Create gerenic map.
      var mapArgs = {
          zoom        : $el.data('zoom') || 16,
          mapTypeId   : google.maps.MapTypeId.ROADMAP,
      };
      var map = new google.maps.Map( $el[0], mapArgs );
  
      // Add markers.
      map.markers = [];
      $markers.each(function(){
          initMarker( $(this), map );
      });
  
      // Center map based on markers.
      centerMap( map );
  
      // Return map instance.
      return map;
    }

    function initMarker( $marker, map ) {

      // Get position from marker.
      var lat = $marker.data('lat');
      var lng = $marker.data('lng');
      var latLng = {
          lat: parseFloat( lat ),
          lng: parseFloat( lng ),
      };
  
      // Create marker instance.
      var marker = new google.maps.Marker({
          position : latLng,
          map: map,
      });
  
      // Append to reference for later use.
      map.markers.push( marker );
  
      // If marker contains HTML, add it to an infoWindow.
      if( $marker.html() ){
  
          // Create info window.
          var infowindow = new google.maps.InfoWindow({
              content: $marker.html(),
          });
  
          // Show info window when marker is clicked.
          google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
          });
      }
    }

    function centerMap( map ) {

      // Create map boundaries from all map markers.
      var bounds = new google.maps.LatLngBounds();
      map.markers.forEach(function( marker ){
          bounds.extend({
              lat: marker.position.lat(),
              lng: marker.position.lng(),
          });
      });
  
      // Case: Single marker.
      if( map.markers.length == 1 ){
          map.setCenter( bounds.getCenter() );
  
      // Case: Multiple markers.
      } else{
          map.fitBounds( bounds );
      }
    }

    jQuery('.acf-map').each(function(){
      var map = initMap( $(this) );
      console.log(map);
    });
  },
};
